:root {
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 600;
  --extra-bold: 700;
  font-family: 'Poppins', serif;
}

body {
  line-height: 1.35 !important;
  font-family: 'Poppins', serif;
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 22px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 14px;
}

.file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.file-label {
  position: relative;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  background: #2983ff;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease-out;
}

.file-label-2 {
  position: relative;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  background: #171456;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease-out;
}

.file-label-3 {
  position: relative;
  width: 200px;
  height: 35px;
  border-radius: 5px;
  background: #3bdfc4;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease-out;
}

.file-label-4 {
  position: relative;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  background: #2983ff;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease-out;
}

.MuiTableContainer-root::-webkit-scrollbar {
  width: 16px;
}

.MuiTableContainer-root::-webkit-scrollbar-track {
  background: #cdc9c1;
}

.MuiTableContainer-root::-webkit-scrollbar-thumb {
  background-color: white;
  border: 3px solid #cdc9c1;
}

.MuiInputBase-input.Mui-disabled {
  color: #181818 !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  color: #232323 !important;
}

.MuiFormLabel-root.Mui-disabled {
  color: #232323 !important;
}

.react-pdf__Page__annotations.annotationLayer {
  height: 0;
}

.scrollit {
  overflow: scroll;
  height: 600px;
}
